/**
 * Check if two maps are equal
 * @param {Map<String, String>} map1
 * @param {Map<String, String>} map2
 * @returns {boolean}
 */
const areMapsEqual = (map1, map2) => {
  if (map1.size !== map2.size) return false;
  return [...map1.entries()].every(([key, value]) => map2.get(key) === value);
};

export default areMapsEqual;
