import { useSelector } from 'react-redux';

import { selectCountryPlugs } from '../store/selectors/countries';

const useAvailablePlug = () => {
  const defaultPlugs = useSelector(selectCountryPlugs);

  return (availableList) => {
    if (defaultPlugs.length === 0) return availableList[0] || null;

    return (
      availableList.find(({ title }) => defaultPlugs.includes(title)) || null
    );
  };
};

export default useAvailablePlug;
